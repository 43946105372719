 
/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

@media (min-width:576px) and (max-width:1000px){
    body{
        min-width: 1320px !important;
    }
}